import React from 'react'

function Blog() {
  return (
    <>
    <h1>The Aquarius Photography Blog</h1>
    <p>This will be the blog page, and will pull info from a cms, probably graphcms.</p>
    </>
  )
}

export default Blog