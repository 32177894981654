import React from 'react'

function Footer() {
  return (
    <div className='footer'>
        <p>Copyright &copy; 2022 Aquarius Photography AZ</p>
    </div>
  )
}

export default Footer