import React from "react";

function About() {
  return (
    <>
      <h1>About Aquarius Photography</h1>
      <p>Put some information here about me and what services I offer.</p>
    </>
  );
}

export default About;
