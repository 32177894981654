import React from "react";

function Home() {
  return (
    <>
      <h1>Welcome to Aquarius Photography!</h1>
      <p>Want to put sample photos here, either from Unsplash or a cms.</p>
      
    </>
  );
}

export default Home;
