import React from 'react'

function Contact() {
  return (
    <>
    <h1>Contact Me</h1>
    <p>Will put a super cool contact form here...</p>
    </>
  )
}

export default Contact