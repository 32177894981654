import React from 'react'

function Pricing() {
  return (
    <>
    <h1>Pricing</h1>
    <p>Here will be the different prices/packages that I offer. Have to come up with my pricing first.</p></>
  )
}

export default Pricing